<!-- 考生报考登记编辑 -->
<template>
    <div class="EditRegister">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">考生报考登记编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" id="form-1" :rules="rules" class="" inline label-width="150px">
            <el-form-item label="登记单号">
                <el-input v-model="form.number" placeholder="请输入登记单号"></el-input>
            </el-form-item>
            <el-form-item label="机构名称">
                <el-select v-model="form.name" filterable placeholder="请输入并选择机构名称"
                 :clearable="true" @change="nameChange">
                    <el-option v-for="(item) in form.nameChoose" :key="item.id"
                        :label="item.name" :value="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="准考证">
                <el-input v-model="form.identity" placeholder="请输入准考证"></el-input>
            </el-form-item>
            <el-form-item label="学员姓名" prop="studentName">
                <el-select v-model="form.studentName" filterable remote placeholder="请输入并选择学员姓名" :clearable="true"
                    :remote-method="students" @change="studentChange">
                    <el-option v-for="item in form.studentOpt" :key="item.id" :label="item.username"
                        :value="item.username">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="身份证号" >
                <el-input v-model="form.idcard" placeholder="请输入身份证号"></el-input>
            </el-form-item>
            <el-form-item label="指导老师" >
                <el-input v-model="form.instructor" placeholder="请输入指导老师"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="telephone">
                <el-input v-model="form.telephone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="申请考试日期">
                <el-date-picker v-model="form.applytestDate" value-format="yyyy-MM-dd"
                type="date" placeholder="请选择考试日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="下单人员" prop="nextPerson">
                <el-select v-model="form.nextPerson" placeholder="请选择下单人员" :clearable="true" @change="nextPersonChange">
                    <el-option v-for="item in form.nextPersonChoose" :key="item.id"
                        :label="item.fullname" :value="item.fullname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="报考日期" >
                <el-date-picker v-model="form.enrollDate"  type="date" placeholder="请选择报考日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="申请大类">
                <el-select v-model="form.bigclass" placeholder="请选择申请大类"
                 :clearable="true" @change="bigclassChange">
                    <el-option v-for="item in form.bigclassChoose" :key="item.id"
                        :label="item.exam_name" :value="item.exam_name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择科目">
                <el-select v-model="form.subject" filterable placeholder="请输入并选择科目" 
                :clearable="true" @change="subjectChange">
                    <el-option v-for="item in form.subjectChoose" :key="item.id"
                        :label="item.course_name" :value="item.course_name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="原等级值">
                <el-select v-model="form.originalGrade" placeholder="请选择原等级值" 
                :clearable="true" >
                    <el-option label="0" :value="0">
                    </el-option>
                    <el-option v-for="item in form.gradeChoose" :key="item.id"
                        :label="item.level" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="报考等级">
                <el-select v-model="form.exam_level" placeholder="请选择" @change="levelChange" :clearable="true" >
                    <el-option v-for="item in form.exam_levelOpt" :key="item.id" :label="item.level" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="考试日期">
                <el-date-picker v-model="form.testDate"  value-format="yyyy-MM-dd"
                type="date" placeholder="请选择报考日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="考试开始时间">
                <el-time-picker
                   v-model="form.startTime"
                   :picker-options="{
                     selectableRange: '00:00:00 - 23:59:59'
                   }"
                   placeholder="请选择开始时间">
                 </el-time-picker>
            </el-form-item>
            <el-form-item label="截止时间">
                <el-time-picker
                   v-model="form.endTime"
                   :picker-options="{
                     selectableRange: '00:00:00 - 23:59:59'
                   }"
                   placeholder="请选择截止时间">
                 </el-time-picker>
            </el-form-item>
            <el-form-item label="考试时长">
                <el-input v-model="form.duration" placeholder="请输入考试时长"></el-input>
            </el-form-item>
            <el-form-item label="副证费">
                <el-input v-model="form.certificateFee" placeholder="请输入副证费"></el-input>
            </el-form-item>
            <el-form-item label="考级报名费">
                <el-input v-model="form.registrationFee" placeholder="请输入考级报名费"></el-input>
            </el-form-item>
            <el-form-item label="考级管理费">
                <el-input v-model="form.manageFee" placeholder="请输入考级管理费"></el-input>
            </el-form-item>
            <el-form-item label="应收费金额">
                <el-input v-model="form.receivable"  placeholder="请输入应收费金额"></el-input>
            </el-form-item>
            <el-form-item label="实收金额">
                <el-input v-model="form.netReceipts" placeholder="请输入实收金额"></el-input>
            </el-form-item>
            <el-form-item label="考试备注">
                <el-input v-model="form.remarks" type="textarea"
                 class="text-ipt" placeholder="请输入考试备注">
                </el-input>
            </el-form-item>
           <el-form-item label="状态">
               <div style="width: 700px;">
                   <el-radio-group v-model="form.condition">
                       <el-radio :label="1">草稿</el-radio>
                       <el-radio :label="2">报考</el-radio>
                       <el-radio :label="3">准考</el-radio>
                       <el-radio :label="4">考试ok</el-radio>
                       <el-radio :label="5">取消</el-radio>
                       <el-radio :label="6">退款取消</el-radio>
                       <el-radio :label="7">延期</el-radio>
                       <el-radio :label="8">缺考</el-radio>
                   </el-radio-group>
               </div>
           </el-form-item>
        </el-form>  
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;"  @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                  number:"",//登记单号
                  name:"",//机构名称
                  name_id:0,//机构id
                  nameChoose:[],//机构名称选项
                  identity:"",//准考证
                  studentName:"",//学员姓名
                  studentOpt: [], //学员选项
                  student_id:'',//学员id
                  instructor:"",//指导老师
                  telephone:"",//联系电话
                  applytestDate:"",//申请考试日期
                  nextPerson:"",//下单人员
                  nextPerson_id:0,//下单人员id
                  nextPersonChoose:[],//下单人员选项
                  enrollDate:"",//报考日期
                  bigclass:"",//申请大类
                  bigclass_id:0,//申请大类id
                  bigclassChoose:[],//申请大类选项
                  subject:"",//选择科目
                  subject_id:0,//科目id
                  subjectChoose:[],//科目选项
                  originalGrade:"",//原等级值
                  gradeChoose:[],//原等级值选择
                  exam_level:"",//报考等级
                  exam_levelOpt:[],//报考等级选项
                  testDate:"",//考试日期
                  startTime:"",//考试开始时间
                  endTime:"",//截止时间
                  duration: "", //考试时长
                  certificateFee:"",//副证费
                  registrationFee:"",//考级报名费
                  manageFee:"",//考级管理费
                  receivable:"",//应收费金额
                  netReceipts:"",//实收金额
                  fraction:"",//考试分数
                  epilog:"",//考试结论
                  grade:"",//选择等级
                  remarks:"",//考试备注
                  condition:1,//状态 
                  idcard:""//身份证
               },
               rules:{
                  studentName:[
                       { required: true, message: '学员姓名不能为空', trigger: 'blur' },
                  ],
                  telephone:[
                       { required: true, message: '联系电话不能为空', trigger: 'blur' },
                  ],
                  applytestDate:[
                       { required: true, message: '申请考试日期不能为空', trigger: 'change' },
                  ],
                  enrollDate:[
                       { required: true, message: '报考日期不能为空', trigger: 'change' },
                  ],
               },
               bool:false,
               Visible:false,
               ruleform:{
                   search:''//关键字
               },
               currentPage: 1,
               currentLimit: 10,//条数
               total: 0,
            }
        },
        computed:{
            listenChange(){
                 const {manageFee,certificateFee,registrationFee} = this.form
               
                 return (+manageFee || 0)+(+certificateFee || 0)+(+registrationFee || 0)
            }
        },
        watch:{
            listenChange(val){
                  this.form.receivable =  parseFloat(val).toFixed(2)
            }
        },
        created() {
            // 初次渲染
            this.$request({url:"/api/examenroll/detail",method:"POST",data:{
                id: this.$route.query.id
            }}).then(res=>{
                console.log(res,'初次渲染')
               if(res.code==1){
                   let data = res.data
                   let form = this.form
                   form.number=data.enroll_number//登记单号
                   form.name_id=data.org_id//机构id
                   form.name=data.org_name//机构名称
                   form.identity=data.certificate_no//准考证
                   form.studentName=data.name//学员姓名
                   form.instructor=data.teacher//指导老师
                   form.telephone=data.phone//联系电话
                   form.applytestDate=data.apply_date//申请考试日期 
                   form.nextPerson_id=data.order_uid//下单人员id
                   form.nextPerson=data.order_name//下单人员
                   form.enrollDate=data.exam_date//报考日期
                   form.bigclass_id=data.apply_category//考试大类id
                   form.bigclass=data.apply//考试大类名称
                   form.subject_id=data.course_id//学科id
                   form.subject=data.course//学科
                   form.originalGrade=data.original_level//原等级
                   form.exam_level=data.exam_level//报考等级
                   form.testDate=data.exam_date2//考试日期
                   form.startTime=data.exam_startdate//考试开始时间
                   form.endTime=data.exam_enddate//截止时间
                   form.duration=data.exam_duration //考试时长
                   form.certificateFee=data.certificatecopy_price//副证费
                   form.registrationFee=data.enroll_price//考级报名费
                   form.manageFee=data.manage_price//考级管理费
                   form.receivable=data.price1//应收费金额
                   form.netReceipts=data.price2//实收金额
                   form.remarks=data.remark//考试备注
                   form.condition=data.status//状态 
                   form.idcard=data.idcard//身份证
                   form.student_id=data.student_id//学员id
               }
            })
            // 选择机构
            this.$request({url:"/api/mechanism/list",method:"POST",data:{
                page:1,
                limit:1000
            }}).then(res=>{
                // console.log("选择机构",res.data.list)
                this.form.nameChoose= res.data.list
            })
            
            // 申请大类
            this.$request({url:"/api/ExamCategory/list",method:"POST",data:{
                page:1,
                limit:100
            }}).then(res=>{
                // console.log("申请大类",res.data.list)
               if(res.code==1){
                   this.form.bigclassChoose= res.data.list
               }
            })
                
            //科目数据
            this.$request({url:"/api/course/list",method:"POST",data:{
                page:1,
                limit:100
            }}).then(res=>{
                // console.log("科目数据",res.data.list)
                if(res.code==1){
                    this.form.subjectChoose= res.data.list
                }    
            })
            
            //原等级值数据
            this.$request({url:"/api/level/list",method:"POST",data:{
                page:1,
                limit:100
            }}).then(res=>{
                // console.log("原等级值数据",res.data.list)
                if(res.code==1){
                    this.form.gradeChoose= res.data.list
                    this.form.exam_levelOpt=res.data.list
                }    
            })
            
            // 下单人数据
            this.$request({url:"/api/humanresources/list",method:"POST",data:{
                page:1,
                limit:100
            }}).then(res=>{
                // console.log("下单人数据",res.data.list)
                if(res.code==1){
                    this.form.nextPersonChoose= res.data.list
                }    
            })
        },
        methods:{
            handleSizeChange(val) {
                this.currentLimit=val
                this.getStudent()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getStudent()
                // console.log(`当前页: ${val}`);
            },
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                        let form = this.form
                        this.$request({
                            url:"/api/examenroll/edit",
                            method:"POST",
                            data:{
                                enroll_number:form.number,//登记单号
                                org_id:form.name_id,//机构ID
                                org_name:form.name,//机构名称
                                certificate_no:form.identity,//准考证号
                                name:form.studentName,	//学生名称
                                teacher:form.instructor,//指导老师
                                phone:form.telephone,//联系电话
                                apply_date:form.applytestDate,//申请考试时间
                                exam_date:form.enrollDate,//报考日期
                                apply_category:form.bigclass_id,//考试大类
                                course_id:form.subject_id,//学科ID
                                original_level:form.originalGrade,//原等级
                                exam_level:form.exam_level,//	报考等级
                                exam_startdate:form.startTime,//考试开始时间
                                exam_enddate:form.endTime,//考试结束时间
                                exam_duration:form.duration,//考试时长
                                certificatecopy_price:form.certificateFee,//副证费
                                enroll_price:form.registrationFee,//考试报名费
                                manage_price:form.manageFee,//考试管理费
                                price1:form.receivable,//应收费用
                                price2:form.netReceipts,//实收费用
                                remark:form.remarks,//说明
                                status:form.condition,//报考状态
                                course:form.subject,//学科名称
                                student_id:form.student_id,//学生ID
                                idcard:form.idcard,//身份证
                                order_uid:form.nextPerson_id,//下单人ID
                                order_name:form.nextPerson,//下单人名字
                                id:this.$route.query.id//报考id
                            }
                        }).then(res=>{
                            console.log(res)
                            if(res.code==1){
                                this.$message({
                                    message: '编辑列表成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }else{
                                this.$message({
                                    message: '新增列表失败',
                                    type: 'error'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.$router.push({
                    path: "register"
                })
                this.$refs[form].resetFields();
            },
            nameChange(e){//获取机构id
                // console.log(e)
                let result = this.form.nameChoose.find(item=>item.name == e)
                this.form.name_id=result.id
                // console.log(this.form.name_id,this.form.name);  
            },
            bigclassChange(e){//考试大类
                let result = this.form.bigclassChoose.find(item=>item.exam_name == e)
                // console.log(result)
                this.form.bigclass_id=result.id
                // console.log(this.form.bigclass_id,this.form.bigclass);
                
            },
            subjectChange(e){  //选择科目              
                let result = this.form.subjectChoose.find(item=>item.course_name == e)
                this.form.subject_id=result.id
                // console.log(this.form.subject_id,this.form.subject);
            },
            nextPersonChange(e){//下单人
                 let result = this.form.nextPersonChoose.find(item=>item.fullname == e)
                 this.form.nextPerson_id=result.id
                 // console.log(this.form.nextPerson_id,this.form.nextPerson);
            },
            // 等级改变  获取报名费用
            levelChange(e){
                // console.log(e)
                let result = this.form.exam_levelOpt.find(item=>item.level == e)
                // console.log(result)
                this.form.registrationFee=result.price
                this.form.receivable=result.price
            },
            //学生数据
            students(e) {
                // console.log(e);
                this.$request({
                    url: "/api/student/list",
                    method: "POST",
                    data: {
                        page: 1,
                        limit: 100,
                        key: e
                    }
                }).then(res => {
                    // console.log("下单人员数据", res.data.list)
                    this.form.studentOpt = res.data.list
                })
            },
            studentChange(e){//学生数据
                // console.log(e)
                let result = this.form.studentOpt.find(item => item.username == e)
                // console.log(result)
                this.form.student_id=result.id
                this.form.studentName=result.username
                // this.form.idcard=result.idcard
                // console.log(result.id,result.idcard)
            },
            
            
            query(){//查询
                this.currentPage=1
                this.getStudent()
            },
            Reset(){//重置
                this.ruleform.search=''//关键字   
                this.getStudent()
            },
        }
    }
</script>

<style scoped="scoped">
    .EditRegister{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #fff ;
        color: #333333;
    }
    /* 单选按钮 */
     #form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;
    
    }
    
    #form-1 ::v-deep.el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }
    
    #form-1 ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
        
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
    .el-table {
         margin: 20px;
     }
     .ruleform ::v-deep .el-input{
         width: 200px !important;
         height: 33px;
         margin-left: 20px;
     }
     .ruleform ::v-deep .el-input__inner {
         width: 200px !important;
         height: 33px;
         margin-left: 20px;
     }
     ::v-deep .el-table__cell {
         color: #000;
         padding: 0px;
         height: 35px;
     }
    ::v-deep .dig .el-dialog{
        width: 900px !important;
        height: 600px !important;
        display: flex !important;
        flex-direction: column !important;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }

   ::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    color: #C0C4CC;
    cursor: not-allowed;
}
</style>
